.footer {
  background-color: #282828;
  min-height: 400px;
  margin-top: 100px;
  .marging-negative-80{
    margin-bottom: -80px;
  }
  .footer-contact {
    top: -45px;
    z-index: 1;
    background-color: #1d59d8;
    box-shadow: 3px 4px 9px rgba(0,0,0,0.3);
    padding-bottom: 15px;
  }
  .text-contact, .text-contact a {
    color: #ffffff;
    font-size: 16px;
    font-weight: 200;
    line-height: 1.8em;
    font-family: "Poppins", sans-serif;
    padding-top: 15px;
  }
  .footer-content {
    padding-top: 70px;
    .logo-footer {
      width: 300px;
    }
  }
  .text,
  a {
    color: #8b8b8b;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 15px;
    :hover,
    :active {
      text-decoration: none !important;
    }
  }
  hr {
    border-color: #8a8a8a;
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.2em;
    margin-bottom: 25px;
    text-transform: capitalize;
    display: inline-block;
    padding-top: 20px;
  }
  .fas,
  .far {
    left: 0px;
    top: 4px;
    color: #ffffff;
    font-size: 36px;
    line-height: 1em;
    padding: 20px;
  }
  .socialMediaLinks{
    .fab{
      font-size: 30px;
      padding: 0px 15px;
      float: right;
      color: white;
      
    }
    :hover{
      color:#1d59d8 !important
    }
  }
}
