.about-us {
  h3 {
    margin-bottom: 50px;
  }
  .text {
    color: #797979;
    font-size: 16px;
    line-height: 1.8em;
    text-align: justify
   
  }

  .founder{
    h3 {
      margin-bottom: 20px;
    }
  }
  .about-us-details {
    background-image: url("../images/aboutus-details.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 600px;
    position: relative;
    padding: 90px 0px 100px;
    background-position: right top;
    margin-top: 50px;
    h2 {
        color:#fff;
        span {
            font-weight: 800
        }
    }
    .text {
        color: #fff;
    }
  }
  .title{
    position: relative;
    color: #08ae8d;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 12px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif
  }
  .section-title {
      h2 {
        position: relative;
        font-size: 34px;
        color: #242424;
        font-weight: 600;
        line-height: 1.4em;
        margin-bottom: 8px
      };
      .subheading {
        position: relative;
        color: #242424;
        font-size: 20px;
        font-style: italic;
        margin-top: 6px;
        font-family: 'Lora', serif;
      }
  }
  .fab{
    font-family: "Font Awesome 5 Brands";
    font-size: 30px;
    padding: 5px 15px;
  }
}
.help-you-grow {
  // background-image: url("../images/home-3.png");
  min-height: 500px;
  position: relative;
  padding: 90px 0px 100px;
  margin-top: 50px;
  background-size: cover;
  color: #fff;
  .why-attribute{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .why-angel-capital {
    li {
      padding: 10px 20px;
      font-size: 20px;
    };
  };
  .card-body {
   h3{ 
     padding-left: 20px;
     padding-right: 20px;
     padding-top:10px;

    }
  }
}
.help-you-grow-main {
  position: relative;
  padding: 90px 0px 70px;
  background-color: #0094d7;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: auto 100%
}


.how-it-works {
  background-color: #eee!important;
  padding-top: 40px;
  padding-bottom: 75px;
  margin-bottom: -45px;
  .card{
    margin-top: 70px;
    :hover{
      background-color: #007bff;
      color: #fff
    }
  };
  h3 {
    font-size: 20px;
    margin-bottom: 90px
  };
  .fas {
    font-size: 40px;
    margin-bottom: 50px;
  };
  .icon{
    width: 150px;
    margin: auto;
    margin-bottom: 40px;
    margin-top: -75px;
    
  };
  .icon:hover{
    background-color: transparent !important;
  }
 
}
.team-pan-photo {
  background-image: url('../images/team-pan.jpg');
  min-height: 300px;
  background-size: cover;
  margin-top: 50px
}

// .vikram-profile {
//   background-image: url('../images/vikram-black.jpg');
//   height: 200px;
//   background-size: cover;
//   background-position: center;
//   padding:10px;
  
//   &:hover {
//     background-image: url('../images/vikram.jpg') !important;

//   }
// }
// .vikram-profile:hover {
//   background-image: url('../images/vikram.jpg') !important;

// }

.vikram-profile , .mayur-profile {
  position: relative !important;
}
// .profile-details {
  .second-visible {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .first-visible {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    @media screen and (max-width: 768px) {
      position: initial;
      top: unset;
      left: unset;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .vikram-profile:hover .second-visible {
    visibility: visible;
  }
  .vikram-profile:hover .first-visible {
    visibility: hidden;
  }


  .second-visible-mayur {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
  .first-visible-mayur {
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    @media screen and (max-width: 768px) {
      position: initial;
      top: unset;
      left: unset;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .mayur-profile:hover .second-visible-mayur {
    visibility: visible;
  }
  .mayur-profile:hover .first-visible-mayur {
    visibility: hidden;
  }
// }

