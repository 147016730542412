.contact-us {
    form{
        width: 100%;
    };

    .text {
    color: #797979;
    font-size: 16px;
    margin-bottom: 30px;
    };

    .fas{
        padding: 5px 30px;
        font-size: 30px;
    }
}