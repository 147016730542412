.service-header {
  background-origin: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  .personal-loan {
    background-image: url("../images/services.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 400px;
    position: relative;
    padding: 170px 0px 100px;
    text-align: center;
    background-position: center top;
    h1 {
      position: relative;
      color: #ffffff;
      font-size: 50px;
      font-weight: 600;
      line-height: 1.2em;
      margin-bottom: 15px;
    }
  }
}

.service-info {
  padding: 100px 0px 20px;
  color: #797979;
  h3 {
    color: #111;
    margin-bottom: 15px;
  }
  h2,
  .media-heading {
    position: relative;
    color: #000000;
    font-size: 35px;
    font-weight: 300;
    line-height: 1.2em;
    margin-bottom: 18px;
  }
  .service-detail-para {
    font-size: 16px;
    line-height: 1.7em;
    margin-bottom: 28px;
  }
  .media-body {
    padding-left: 20px;
  }
}

.benefits-of-service {
  // padding-top: 50px;
  ul {
    padding: 30px;
    li {
      position: relative;
      margin-bottom: 15px;
      color: #242424;
      font-size: 16px;
      padding-left: 24px;
      font-family: "Poppins", sans-serif;
      list-style: none;
    }
    li:before {
      position: absolute;
      content: "";
      left: 0px;
      top: 11px;
      width: 6px;
      height: 6px;
      background-color: #2c62bd;
    }
  }
}


.services-cards{
  .view-all-servies{
    padding: 10px 40px;
    line-height: 29px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1px;
    border-radius: 50px;
    background-color: #2c62bd;
    border: 2px solid #2c62bd;
    font-family: 'Poppins', sans-serif;
  };
  // .card:hover{
  //   background-color: #007bff;
  //   color: #fff !important;
  //   a{
  //   color: #fff !important;
      
  //   }
  // }
}

.apply-now {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url('../images/apply-now-3.jpg');
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  color: #fff;
  min-height: 350px;
  h1{
    margin-bottom: 30px;
  };
  a{
    padding: 15px 100px;
    font-size: 18px;
  }
}

.faq-search {
  padding:30px  50px !important;
  font-size: 1.3rem
}


// @media only screen and (min-width : 320px) {
//   .card-cap-services {
//     height: 250px !important;
//   }
// }

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .card-cap-services {
    height: 250px !important;

  }
}