.nav-style {
  a {
    // color: #fff !important;
    // font-size: 16px;
    position: relative;
    display: block;
    font-size: 16px;
    color: #ffffff !important;
    line-height: 30px;
    font-weight: 500;
    opacity: 1;
    text-transform: capitalize;
    padding: 30px 0px 15px;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: "Poppins", sans-serif;
  }
  a:after {
    position: absolute;
    content: "";
    left: 0px;
    bottom: 10px;
    width: 23px;
    height: 1px;
    opacity: 0;
    /* background-color: #08ae8d; */
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
  }

  .dropdown-menu-right a {
    position: relative;
    display: block;
    padding: 9px 25px;
    line-height: 24px;
    font-weight: 400;
    font-size: 15px;
    color: #242424 !important;
    text-align: left;
    padding-right: 20px;
    text-transform: capitalize;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: "Poppins", sans-serif;
  }
}
.header {
  .scrolled {
    background-color: $dark-background !important;
    color: #fff;
    transition: 0.6s ease;
    padding-top: 0px !important;
  }
  .transparent {
    background-color: transparent !important;
    transition: 0.6s ease;
   
  }
  @media only screen and (max-width : 480px) {
    .transparent {
      background-color: $dark-background !important;
  
    }
  }
}

.navbar-brand {
  img{
  width: 200px;
  margin-top: -12px;};
};

.hero-images {
  width: 100%;
 
  .hero-backgroud .personalLoan{
    background: url('../images/personal.jpg');
    background-origin: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
}
.hero-background {
  background-image: url('../images/home-dark.jpg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 900px;
  .carousel{
    position: relative;
    top: 30%;
  };
  h4{
    color: #c5c5c5 !important
  }
}
.slick-initialized {
  width: 100% !important;
  position:absolute;
  .slick-prev{
    left:0;
  }
}

.slideDiv {
  color: #fff;
  text-shadow: 1px 0px 0px #111;
}