.accordion {
  border: 0px solid rgba(0, 0, 0, 0.1) !important;
  .accordion__title{ 
    background-color: #fff !important;  
    border: 1px solid rgba(0, 0, 0, 0.1) !important;

    h4{
    font-size: 18px !important;
    line-height: 25px;
    color: #242424 !important;
    font-weight: 500;
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
  }};
  .accordion__body--hidden, .accordion__body{
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  };
  .accordion__body {
    background-color: #f9f9f9;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;

  }
}

.accordion__item{
  margin-bottom: 15px;
}