.emi-detail-values{
    padding: 15px;
    border-right: 1px solid rgba(0,0,0,.125);
    border-bottom: 1px solid rgba(0,0,0,.125);
  
};
.border-bottom-none{
    border-bottom: none !important;
};
.results {
    border-top: 1px solid rgba(0,0,0,.125);
    margin-bottom: -20px

}

.emi-calculator-inputs {

    .form-group{
        border-bottom: 1px solid rgba(0,0,0,.125);
        padding-bottom: 30px;
        padding-top: 10px;
    }
}

.amortization-table{
    border: 1px solid #ddd
}

.table-header {
    padding: 10px 15px;
    background-color: #0094d7;
    margin-bottom: 20px !important;
    // border: .5px solid #ddd;
    
    color: white;
    div {
        padding:  5px;
    }
}

.table-cell{
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0,0,0,.125);
    border-right: 1px solid rgba(0,0,0,.125);
    div {
        padding:  8px;
    }
}

.table-summaryCell{
    padding: 10px 15px;
    border-bottom: 1px solid rgba(0,0,0,.125);

    div {
        padding:  8px;
    }
}

.amortization-table {
    margin: 20px auto;
    width:  100%;
}

.download-as-excel {
    margin: 5px auto;
}